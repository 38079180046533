import React from 'react'
import {Router} from '@reach/router'
import Layout from '../components/Layout'
import PrivateRoute from '../components/PrivateRoute'
import Quest from '../components/Quest'

const Q = () => (
    <Layout>
        <Router>
            <PrivateRoute path="/q/:hash" component={Quest}/>
        </Router>
    </Layout>
)

export default Q
