import React from 'react'
import Loader from './Loader'
import localization from '../lang'
import { getReport } from '../utils/api'
import { NotificationManager } from './Notification'
import NotFound from '../pages/404'
import Questionnaire from './Report/questionnaire'
import Error from '../pages/500'
import ReportTierEnum from './Report/ReportTierEnum'

class Quest extends React.Component {
  state = {
    isLoading: false,
    language: 'en_US',
    reportHash: null,
    reportState: 'unknown',
    reportType: 'regular',
    reportTier: ReportTierEnum.TIER_SUMMARY,
    questionsTotal: 0,
    questionsAnswered: 0,
    reportFound: true,
    errorEncountered: false,
    consent: {
      tosAccepted: false,
      dataConsentGiven: false,
    }
  }

  componentDidMount = () => {
    // Fetch basic information about the report by provided hash
    const { hash } = this.props
    getReport(hash, {}, () => {
      this.setState({
        isLoading: true,
      })
    })
      .then(response => {
        const { data } = response.data
        this.setState({
          language: data.preferred_language,
          reportState: data.status,
          reportType: data.type,
          questionsTotal: data.questions_total,
          questionsAnswered: data.questions_answered,
          reportHash: hash,
          reportTier: data.code ?? ReportTierEnum.TIER_SUMMARY,
          consent: {
            tosAccepted: data.consent.tos_accepted,
            dataConsentGiven: data.consent.data_consent_given,
          },
        })
      })
      .catch(e => {
        const { response } = e

        if (response === undefined) {
          this.setState({
            errorEncountered: true,
          })
          return
        }

        const { errors } = response.data

        if (e.response.status === 404) {
          this.setState({
            reportFound: false,
          })
        }

        for (const err of errors) {
          NotificationManager.error(err.title, 3000)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    const {
      isLoading,
      language,
      reportFound,
      reportState,
      reportHash,
      reportType,
      reportTier,
      questionsTotal,
      questionsAnswered,
      errorEncountered,
        consent,
    } = this.state

    let locale;
    if (localization.hasOwnProperty(language)) {
      locale = localization[language]
    } else {
      locale = localization['en_US']
    }

    if (errorEncountered === true) {
      return <Error />
    }

    if (reportFound !== true) {
      return <NotFound />
    }

    let questionnaireTitle = ''

    if (locale.questionnaire.hasOwnProperty(reportTier)) {
      questionnaireTitle = locale.questionnaire[reportTier].title
    }

    return (
      <div className={'min-w-screen min-h-screen flex items-center justify-center relative'}>
        <Loader active={isLoading} title={locale.app.loading.title} message={locale.app.loading.message} />
        {/* Report is ready to be filled */}
        {['new', 'in_progress'].includes(reportState) &&
          <Questionnaire
            hash={reportHash}
            report={{
              state: reportState,
              type: reportType,
              code: reportTier,
              questionsTotal: questionsTotal,
              questionsAnswered: questionsAnswered,
              consent: consent,
            }}
            language={language}
          />
        }

        {/* Report is waiting to be processed */}
        {['waiting_for_report', 'evaluated'].includes(reportState) &&
            <div className="container mx-5 my-5">
                <div className="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
                    <div className="text-center">
                        <h2 className="text-4xl tracking-tight leading-10 text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                            <span className={'text-indigo-600'}>{questionnaireTitle}</span> {locale.page.reportEvaluated.title}
                        </h2>
                        <h3 className='text-xl md:text-3xl mt-10'>{locale.page.reportEvaluated.comingSoon}</h3>
                        <p className="text-md md:text-xl mt-10">
                            {locale.page.reportEvaluated.text}
                        </p>
                    </div>
                </div>
            </div>
        }

        {/* Report is done and ready. Reports in state `finalizing` are just missing the PDF */}
        {['completed', 'finalizing'].includes(reportState) &&
          <div/>
        }
      </div>
    )
  }
}

export default Quest
